import { mapGetters, mapActions } from 'vuex'
import ZbBillingTicketsMixin from './ZbBillingTicketsMixin'
import { ServiceFactory } from '@/services/ServiceFactory'
const _settingsService = ServiceFactory.get('SettingsService')

export default {
  name: 'TabBarProfileComponent',
  mixins: [ ZbBillingTicketsMixin ],
  data () {
    return {
      settings: {
        workingSchedule: []
      },
      dialog: false,
      selectedLang: localStorage.getItem('currentLang') || 'es',
      availableLangs: []
    }
  },
  created: function () {
    this.translateDropdown(this.currentLang$)
    this.initialize()
  },
  watch: {
    selectedLang: {
      handler: function (newVal) {
        this.translateDropdown(newVal)
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('settingsModule', [
      'titleBar$',
      'bannerBarUrl$',
      'profileBarUrl$',
      'location$',
      'slogan$',
      'telephone$',
      'socialMedia$',
      'workingSchedule$',
      'maxPeopleCapacity$',
      'address$'
    ]),
    ...mapGetters({
      typeBusiness$: 'settingsModule/typeBusiness',
      currentLang$: 'langModule/currentLang'
    })
  },
  methods: {
    ...mapActions('langModule', ['setLang']),
    async initialize () {
      try {
        const { settings } = await _settingsService.get()
        const { name, slogan, workingSchedule, location, address, maxPeopleCapacity, telephone, socialMedia } = await settings
        this.settings = { name, slogan, workingSchedule, location, address, maxPeopleCapacity, telephone, socialMedia }
        await this.updateWorkingSchedule(workingSchedule)
      } catch (error) {
        this.$notify({
          title: 'Error',
          type: 'error',
          text: error.msg
        })
      }
    },
    selectLang () {
      const currentLang = this.selectedLang
      this.setLang({ currentLang })
      this.$i18n.locale = this.currentLang$
      this.$vuetify.lang.current = this.currentLang$
    },
    translateDropdown (locale) {
      this.availableLangs = [
        {
          value: this.$i18n.t('general.en', locale),
          id: 'en'
        },
        {
          value: this.$i18n.t('general.es', locale),
          id: 'es'
        }
      ]
    },
    goFormBarProfile () {
      this.$router.push({ name: 'form-bar-profile' })
    },
    async updateWorkingSchedule (workingScheduleItem) {
      try {
        const { settings } = await _settingsService.put({
          workingSchedule: workingScheduleItem
        })
        this.settings.workingSchedule = await settings.workingSchedule
      } catch (error) {
        this.$notify({
          title: 'Error',
          type: 'error',
          text: error.msg
        })
      }
    }
  }
}
