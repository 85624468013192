import { ServiceFactory } from '@/services/ServiceFactory'
const BillingService = ServiceFactory.get('BillingService')

export default {
  data () {
    return {
      ticketsMusic: {},
      ticketsBooking: {},
      ticketsDesactivates: null,
      headersTickets: [
        {
          text: 'Dessert (100g serving)',
          align: 'start',
          value: 'name'
        },
        { text: 'Calories', value: 'calories' }
      ],
      ticketsData: [
        { active: true, title: 'Jason Oner', avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg' },
        { active: true, title: 'Ranee Carlson', avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg' },
        { title: 'Cindy Baker', avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg' },
        { title: 'Ali Connors', avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg' }
      ]
    }
  },
  watch: {
    async tab () {
      if (this.tab === 'tab-1') {
        this.getTicketsBilling()
      }
    }
  },
  methods: {
    async getTicketsBilling () {
      try {
        const ticketsMusic = await BillingService.getMusic()
        this.ticketsMusic = ticketsMusic
      } catch (error) {
        this.ticketsMusic = null
        this.ticketsDesactivates = error.msg
      }
      try {
        const ticketsBooking = await BillingService.getBooking()
        this.ticketsBooking = ticketsBooking
      } catch (error) {
        this.ticketsBooking = null
        this.ticketsDesactivates = error.msg
      }
    }
  }
}
